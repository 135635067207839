import React from "react";
import ABOUT from '../../img/about-photo.jpg';
import {FiAward, FiCheckSquare} from 'react-icons/fi';
import {FaUniversity} from 'react-icons/fa';

import './About.css';

export const About = () =>{
    return(
        <>
            <section id='about'>
                <h5>Get To Know</h5>
                <h2>About me!</h2>

                <div className="container about__container">
                    <div className="about__me">
                        <div className="about__me-image">
                            <img src={ABOUT} alt="About Image"/>
                        </div>

                    </div>
                    <div className="about__content">
                        <div className="about__cards">
                            <article className="about__card">
                                <FiAward className='about__icon'/>
                                <h5>14-months course</h5>
                                <small><a href='https://www.megak.pl/'>Mega Kurs! - Backend JS with React</a></small>
                            </article>
                            <article className="about__card">
                                <FaUniversity className='about__icon'/>
                                <h5>Finished School</h5>
                                <small>Wrocław University of Technology </small>
                            </article>
                            <article className="about__card">
                                <FiCheckSquare className='about__icon'/>
                                <h5>Ready to work!</h5>
                                <small></small>
                            </article>
                        </div>
                        <p>
                            Right now my main goal is to find a job
                            that will give me an opportunity to deepen and improve my programming skills.
                        </p>
                        <a href="#contact" className='btn btn-primary'>Let's Talk</a>
                    </div>
                </div>
            </section>
        </>
    )
}
