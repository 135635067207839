import React from 'react';
import {Home} from "../Home/Home";
import {HeaderSocial} from "./HeaderSocial";

import HIREME from "../../img/portfolio.png"
import './Header.css';

export const Header = () => {
    return (
        <>
            <header className="container header__container" id='header'>
                <h5>Hello I'm</h5>
                <h1>Hubert</h1>
                <h5 className="text-light">JavaScript Developer (backend preferred)</h5>
                <Home/>
                <HeaderSocial/>
                <div className="my">
                    <img src={HIREME} alt=""/>
                </div>
            </header>
        </>
    );
};
