import React from "react";
import {About} from "./components/About/About";
import {Header} from "./components/Header/Header";
import {Contact} from "./components/Contact/Contact";
import {Menu} from "./components/Menu/Menu";
import {Portfolio} from "./components/Portfolio/Portfolio";
import {Footer} from "./components/Footer/Footer";

import './App.css';
import {Experience} from "./components/Experience/Experience";

export function App() {
  return (
      <>
            <Header />
            <Menu/>
            <About/>
            <Experience/>
            <Portfolio/>
          <Contact/>
          <Footer/>
      </>
  );
}
