import React from 'react';
import './index.css';
import {App} from './App';
import { createRoot } from "react-dom/client";
import {BrowserRouter as Router} from "react-router-dom";

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
      <Router >
          <App/>
      </Router>
  </React.StrictMode>
);
