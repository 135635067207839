import IMG1 from '../../img/project1.png';
import IMG2 from '../../img/project5.png';
import {PortfolioArticle} from "./PortfolioArticle";

import './Portfolio.css';

const data = [
    {
        id: 1,
        image: IMG2,
        title: 'Play poker with AI!',
        githubFE: 'https://github.com/hubertkucharski/poker-front',
        githubBE: 'https://github.com/hubertkucharski/poker-back',
        demo: 'https://poker.hubertk.pl/',
    },
    {
        id: 2,
        image: IMG1,
        title: 'React + NestJS + MySql',
        githubFE: 'https://github.com/hubertkucharski/mhFront',
        githubBE: 'https://github.com/hubertkucharski/mhBack',
        demo: 'https://mh.hubertk.pl/',
    },
    // {
        // id: 3,
        // image: IMG2,
        // title: 'Express articles',
        // githubFE: 'https://github.com/hubertkucharski/express-her',
        // githubBE: '',
        // demo: 'https://express-uno.herokuapp.com/',
    // }
    ]

export const Portfolio = () => {
    return (
        <>
            <section id='portfolio'>
                <h5>My Recent Work</h5>
                <h2>Portfolio</h2>

                <div className="container portfolio__container">
                    {
                        data.map(({id, image, title, githubFE, githubBE, demo}) =>
                            <PortfolioArticle
                                key={id}
                                image={image}
                                title={title}
                                githubFE={githubFE}
                                githubBE={githubBE}
                                demo={demo}/>
                        )
                    }
                </div>

            </section>

        </>
    );
};


