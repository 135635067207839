import {BsLinkedin, BsGithub} from 'react-icons/bs';
import './Footer.css';

export const Footer = () => {
    return (
        <footer>
            <a href="#" className="footer__logo">TOP</a>
            <ul className="permalinks">
                <li><a href="#">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#experience">Experience</a></li>
                <li><a href="#portfolio">Portfolio</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>
            <div className="footer__socials">
                <a href="https://www.linkedin.com/in/hubert-kucharski/" target="_git" rel="noreferrer"><BsLinkedin/></a>
                <a href="https://github.com/hubertkucharski" target="_git" rel="noreferrer"><BsGithub/></a>
            </div>
        </footer>
    );
};
