interface Props{
    // id: number,
    image: string,
    title: string,
    githubFE: string,
    githubBE: string,
    demo: string,
}

export const PortfolioArticle = (props: Props) => {
    const {image, title, githubFE, githubBE, demo} = props;
    return (
        <article className="portfolio__item">
            <div className="portfolio__item-image">
                <img src={image} alt='portfolio img'/>
            </div>
            <h3>{title}</h3>
            <div className="portfolio__item-cta">
                <a href={githubFE} className='btn' target="_git" rel="noreferrer">Git FE</a>
                {githubBE && <a href={githubBE} className='btn' target="_git" rel="noreferrer">Git BE</a>}
                <a href={demo} className='btn btn-primary' target="_git" rel="noreferrer">Link</a>
            </div>
        </article>
    );
}
