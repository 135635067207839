import './Experience.css';
import {BsPatchCheck, BsPatchCheckFill} from 'react-icons/bs';

export const Experience = () =>{
    return(
        <section id='experience'>
            <h5>What Skills I have</h5>
            <h2>My Experience</h2>

            <div className="container experience__container">
                <div className="experience__frontend">
                    <h3>Frontend Development</h3>
                    <div className="experience__content">
                        <article className="experience__details">
                            <BsPatchCheckFill/>
                            <div>
                                <h4>React</h4>
                            </div>
                        </article>
                        <article className="experience__details">
                            <BsPatchCheckFill/>
                            <div>
                                <h4>CSS</h4>
                            </div>
                        </article>
                        <article className="experience__details">
                            <BsPatchCheckFill/>
                            <div>
                                <h4>TS</h4>
                            </div>
                        </article>
                    </div>
                </div>

                <div className="experience__backend">
                    <h3>Backend Development</h3>
                    <div className="experience__content">
                        <article className="experience__details">
                            <BsPatchCheck/>
                            <div>
                                <h4>MySql</h4>
                            </div>
                        </article>
                        <article className="experience__details">
                            <BsPatchCheck/>
                            <div>
                                <h4>MongoDB</h4>
                            </div>
                        </article>
                        <article className="experience__details">
                            <BsPatchCheck/>
                            <div>
                                <h4>Express.js</h4>
                            </div>
                        </article>
                        <article className="experience__details">
                            <BsPatchCheck/>
                            <div>
                                <h4>NestJS</h4>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>
    )
}
