import React, {useState} from "react";
import './Menu.css'
import {BiHomeSmile, BiBook, BiDumbbell} from 'react-icons/bi';
import {SiAboutdotme} from 'react-icons/si';
import {MdOutlineContactMail} from 'react-icons/md';

import './Menu.css'

export const Menu = () =>{

    const [isActive, setIsActive ] = useState('#')

    return (
        <nav>
            <a href="#"
               className={isActive === '#' ? 'active' : '' }
               onClick={()=> setIsActive('#')}>
                <BiHomeSmile/>
            </a>
            <a href='#about'
                className={isActive === '#about' ? 'active' : '' }
                onClick={()=> setIsActive('#about')}>
                <SiAboutdotme/>
            </a>
            <a href="#experience"
               className={isActive === '#experience' ? 'active' : '' }
               onClick={()=> setIsActive('#experience')}>
                <BiDumbbell/>
            </a>
            <a href="#portfolio"
               className={isActive === '#portfolio' ? 'active' : '' }
               onClick={()=> setIsActive('#portfolio')}>
                <BiBook/>
            </a>
            <a href="#contact"
                className={isActive === '#contact' ? 'active' : '' }
                onClick={()=> setIsActive('#contact')}>
                <MdOutlineContactMail/>
            </a>
        </nav>
    )
}
