import {HiOutlineMail} from 'react-icons/hi';
import {FaWhatsapp} from 'react-icons/fa';
import {useState} from "react";
import emailjs from '@emailjs/browser';

import './Contact.css';

export const Contact = () => {

    const [isSend, setIsSend] = useState<number>(100)

    const sendEmail = (e: any) => {
        e.preventDefault();

        emailjs.sendForm('service_8zuu2sm', 'template_a2bmstb', e.currentTarget, '7DFRinhYJykoRWuAC')
            .then((result) => {
                setIsSend(result.status)
            }, (error) => {
                console.log(error.text);
            });
    }
    if (isSend === 200) {
        return(
            <section className="contact">
                <h2>Thank you for your message!</h2>
                <h5>I will respond as soon as possible.</h5>
            </section>
            )

    }
        return (
            <>
                <section id='contact'>
                    <h5>Get In Touch</h5>
                    <h2>Contact Me</h2>

                    <div className="container contact__container">
                        <div className="contact__options">
                            <article className="contact__option">
                                <HiOutlineMail className='contact__options-icon'/>
                                <h4>Email</h4>
                                {/*<h5></h5>*/}
                                <a href="mailto:hubertkucharski@gmail.com" target="_git" rel="noreferrer">Send an
                                    email</a>
                            </article>
                            <article className="contact__option">
                                <FaWhatsapp className='contact__options-icon'/>
                                <h4>What's app</h4>
                                {/*<h5>nr</h5>*/}
                                <a href="https://api.whatsapp.com/send?phone=48792488448" target="_git"
                                   rel="noreferrer">Send a message</a>
                            </article>
                        </div>
                        <form onSubmit={sendEmail}>
                            <input type="text" name='name' placeholder='Your Full Name' required/>
                            <input type="email" name='email' placeholder='Your Email' required/>
                            <textarea name='message' placeholder='Your Message' required>

                        </textarea>
                            <button type='submit' className='btn btn-primary'>Send Message</button>
                        </form>
                    </div>
                </section>
            </>
        );

};
